import React, {useState} from 'react';
import {Message} from "../../../../../theme/styledComponents";
import Input from "../../../../../components/input";
import Button from "../../../../../components/button";
import axios from "axios";
import {connect} from "react-redux";
import {getCookie} from "../../../../../theme/cookies";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";
import Spinner from "../../../../../components/spinner";
import {P} from "../../../../../components/p";
import { API_URL } from '../../../../../config';

function ActivateDriver (props) {
    const {driver,setShow,refresh,user} = props;

    const [loading,setLoading] = useState();
    const [error,setError] = useState();

    const [state,setState] = useState('');

    const onSubmit = () => {
        setLoading(true);
        axios.patch(`${API_URL}/companies/${user.CompanyId}/drivers/${driver.PersonId}/draft/?password=${state}`,state,{
            headers:{
                'Authorization': getCookie('token')
            }
        })
            .then(res => {
                refresh();
                setLoading(false);
                setShow(false);

            })
            .catch(err => {
                setError(true);
                setLoading(false);
            })
    };

    return(
        <>
            {
                error &&
                <Message>
                    <FontAwesomeIcon icon={faExclamationTriangle}/> Nie udało się aktywować aplikacji.
                </Message>

            }
            <P>Aktywuj aplikację dla pojazdu <span>{driver.Username}</span>.</P>
            <Input password autoComplete="new-password" label="Hasło*" value={state} onChange={(e) => setState(e.target.value)}/>
            <Button big style={{marginTop:'10px'}} onClick={onSubmit} disabled={state.length < 3}>
                {
                    loading ?
                        <Spinner borderWidth={3} white width={10} height={10}/> :
                        'Aktywuj aplikacje'
                }
            </Button>
        </>
    )
};

ActivateDriver.propTypes = {
};

function mapStateToProps(state) {
    return {
        user:state.auth.user
    };
}
export default connect(mapStateToProps)(ActivateDriver);
