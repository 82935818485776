import styled from 'styled-components';

export const P = styled.p`
  font-size: ${({theme}) => theme.font.S};
  font-weight: ${({theme}) => theme.font.Light};
  color: ${({theme}) => theme.secondColor};
  margin: ${({margin}) => margin || '0 0 10px'};
  
  span{
      font-weight: ${({theme}) => theme.font.Regular};
  }
`;
