import React from "react";
import styled from "styled-components"
import Title from "../../../../components/title";
import DeleteUserFromAppUser from "./blocks/deleteUserFromAppUser";

const Settings = () => {
  return (
    <Wrapper>
      <Title>Dodatkowe opcje</Title>
      <Flex>
        <DeleteUserFromAppUser />
      </Flex>
    </Wrapper>
  )
}

export default Settings

const Flex = styled.div`
  display: flex;
  margin-top: 20px;
  `;

const Wrapper = styled.div`
  
`;