import React from 'react';
import styled, {css} from 'styled-components'
import {CSSTransition} from 'react-transition-group';
import Title from "./title";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import {Flex} from "../theme/styledComponents";

function Modal ({show,setShow,maxWidth = 300,title,rightModal,buttonAction,children}) {
    return(
        <>
            <CSSTransition
                in={show}
                timeout={300}
                classNames="background"
                unmountOnExit
            >
                <Background onClick={() => setShow(false)}/>
            </CSSTransition>
            <CSSTransition
                in={show}
                timeout={300}
                classNames="content"
                unmountOnExit
            >
                <Content onClick={(e) => e.stopPropagation()} maxWidth={maxWidth} rightModal={rightModal}>
                    <ContentScroll padding={rightModal ? "20px 20px 40px" : "15px"}>
                        <Flex jc="space-between" margin="0 0 15px">
                            {
                                rightModal ?
                                    <Title>{title}</Title> :
                                    <Title secondary>{title}</Title>
                            }
                            <div onClick={() => setShow(false)} style={{cursor:'pointer'}}>
                                <FontAwesomeIcon icon={faTimesCircle}/>
                            </div>
                        </Flex>
                        {
                            children
                        }
                    </ContentScroll>
                </Content>
            </CSSTransition>
        </>
    )
};

Modal.propTypes = {
};

export default Modal;

const ContentScroll = styled.div`
  overflow: scroll;
  flex: 1 1;
  padding: ${({padding}) => padding};
  
  &::-webkit-scrollbar {
    width: 0 !important
}
`;


const Content = styled.div`
  background: white;
  position: absolute;
  max-width: ${({maxWidth}) => maxWidth}px;
  width: 100%;
  height: auto;
  right: 50%;
  transform: translateX(50%);
  top: 20%;
  transition: all 0.3s;
  border-radius: 5px;
  
  &.content-enter {
      transform: translateX(50%) translateY(-50px);
      opacity: 0;
    }
    &.content-enter-active {
      transform: translateX(50%) translateY(0px);
       opacity: 1;
          }
    &.content-exit {
      transform: translateX(50%) translateY(0px);
       opacity: 1;
    }
    &.content-exit-active {
      transform: translateX(50%) translateY(-50px);
       opacity: 0;
    }
    
    ${({rightModal}) => rightModal && css`
      height: 100vh;
      top: 0px;
      right: 0px;
      transform: translateX(0%);
      border-radius: 0px;
      overflow: scroll;
      max-height: 100%;
      display: flex;
      flex-direction: column;
      
      &.content-enter {
          transform: translateX(100%);
          opacity: 0;
        }
        &.content-enter-active {
          transform: translateX(0%);
           opacity: 1;
              }
        &.content-exit {
          transform: translateX(0%);
           opacity: 1;
        }
        &.content-exit-active {
          transform: translateX(100%);
           opacity: 0;
        }
    `}
`;

const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  background: rgba(0,0,0,0.6);
  transition: all 0.3s;
  
    &.background-enter {
      opacity: 0;
    }
    &.background-enter-active {
      opacity: 1;
    }
    &.background-exit {
      opacity: 1;
    }
    &.background-exit-active {
      opacity: 0;
    }
`;
