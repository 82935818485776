import axios from 'axios';
import {eraseCookie, setCookie} from "../../theme/cookies";
import { API_URL } from '../../config';

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";

export const VERIFY_REQUEST = "VERIFY_REQUEST";
export const VERIFY_SUCCESS = "VERIFY_SUCCESS";
export const VERIFY_ERROR = "VERIFY_ERROR";


const requestLogin = () => {
    return {
        type: LOGIN_REQUEST
    };
};

const receiveLogin = user => {
    return {
        type: LOGIN_SUCCESS,
        user
    };
};

const loginError = () => {
    return {
        type: LOGIN_FAILURE
    };
};

const requestLogout = () => {
    return {
        type: LOGOUT_REQUEST
    };
};

const receiveLogout = () => {
    return {
        type: LOGOUT_SUCCESS
    };
};

// const logoutError = () => {
//     return {
//         type: LOGOUT_FAILURE
//     };
// };
//
// const verifyRequest = () => {
//     return {
//         type: VERIFY_REQUEST
//     };
// };
//
// const verifySuccess = () => {
//     return {
//         type: VERIFY_SUCCESS
//     };
// };
//
// const verifyError = () => {
//     return {
//         type: VERIFY_ERROR
//     };
// };

export const loginUser = (email, password) => dispatch => {
    dispatch(requestLogin());
    axios.post(`${API_URL}/Authenticate`,{
        User:email,
        Password:password
    }).then(res => {
        if(res.data.Role === 'ROLE_ADMIN' || res.data.Role === 'ROLE_COMPANY'){
            setCookie("token",`Bearer ${res.data.Token}`,res.data.ExpireIn);
            dispatch(receiveLogin({
                ...res.data
            }));
        }else {
            dispatch(loginError());
        }
    }).catch(err => {
        dispatch(loginError());
    })
};

export const logoutUser = () => dispatch => {
    dispatch(requestLogout());
    eraseCookie("token");
    dispatch(receiveLogout());
};