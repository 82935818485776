import React from 'react';
import styled from 'styled-components'
import {connect} from "react-redux";

function Dashboard (props) {
    return(
        <Wrapper>
            {props.children}
        </Wrapper>
    )
};

Dashboard.propTypes = {
};

function mapStateToProps(state) {
    return {
        isLoggingOut: state.auth.isLoggingOut,
        logoutError: state.auth.logoutError,
        user:state.auth.user
    };
}
export default connect(mapStateToProps)(Dashboard);

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
`;
