import React, {useState} from 'react';
import styled from 'styled-components'
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import {loginUser} from "../../redux/actions";
import Input from "../../components/input";
import Button from "../../components/button";
import Title from "../../components/title";
import LOGO from '../../images/logo_mkierowca.png'
import LOGO_MBIS from '../../images/logo.svg'
import BACKGROUND from '../../images/back.jpg'
import Spinner from "../../components/spinner";
import {Message} from "../../theme/styledComponents";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";
import {P} from "../../components/p";

function Login (props) {
    const {isLoggingIn, loginError, isAuthenticated,dispatch } = props;
    const [state,setState] = useState({
        username:'',
        password:''
    });

    const handleSubmit = () => {
        const { username, password } = state;
        dispatch(loginUser(username, password));
    };

    if (isAuthenticated) {
        return <Redirect to="/panel" />;
    } else {
        return (
            <Wrapper>
                <FormWrapper>
                    <div style={{flex:1,display:'flex',alignItems:'flex-end',justifyContent:'center'}}>
                        <LogoImage alt="Logo Marcos Bis" src={LOGO}/>
                    </div>
                    <Form>
                        <Title margin="0 0 20px">Logowanie</Title>
                        {
                            loginError &&
                            <Message>
                                <FontAwesomeIcon icon={faExclamationTriangle}/> Nie udało się zalogować
                            </Message>
                        }
                        <Input label="Username" name="email" value={state.username} onChange={(e) => setState({...state,username:e.target.value})}/>
                        <Input password label="Hasło" name="password" value={state.password} onChange={(e) => setState({...state,password:e.target.value})}/>
                        <Button big onClick={handleSubmit}>
                            {
                                isLoggingIn ?
                                    <Spinner borderWidth={3} white width={10} height={10}/> :
                                'Zaloguj się'
                            }
                        </Button>

                    </Form>
                    <div style={{flex:1,display:'flex',alignItems:'flex-end',justifyContent:'center'}}>
                        <MBisImage alt="Logo Marcos Bis" src={LOGO_MBIS}/>
                    </div>
                    <div style={{display:'flex',alignItems:'flex-end',justifyContent:'center'}}>
                        {/* TODO: Wrócić na REACT_APP_VERSION po usunięciu portalu pod legacy adresem */}
                        <P>v 2.0.0</P>
                        {/* <P>v {process.env.REACT_APP_VERSION}</P> */}
                    </div>
                </FormWrapper>
                <ImageWrapper>
                    <img src={BACKGROUND}/>
                </ImageWrapper>
            </Wrapper>
        )
    }
};

Login.propTypes = {
};

function mapStateToProps(state) {
    return {
        isLoggingIn: state.auth.isLoggingIn,
        loginError: state.auth.loginError,
        isAuthenticated: state.auth.isAuthenticated
    };
}
export default connect(mapStateToProps)(Login);

const MBisImage = styled.img`
  height: 30px;
  //filter: grayscale(100%);
    margin: 0 auto;
  margin-bottom: 40px;
  display: block;
`;

const LogoImage = styled.img`
  height: 60px;
  display: block;
  margin: 0 auto;
    margin-bottom: 80px;
`;


const ImageWrapper = styled.div`
  width: 65%;
  height: 100%;

  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.9;
  }
  
@media (max-width: 1300px){
    width: 50%;
  }
`;

const Form = styled.div`
  width: 90%;
  max-width: 350px;
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 35%;
  height: 100%;
  background: white;
  
  @media (max-width: 1300px){
    width: 50%;
  }
`;

const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;
  position: fixed;
  left: 0px;
  top: 0px;
  display: flex;
`;
