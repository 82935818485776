import React from 'react';
import Navigation from "./navigation/navigation";
import {connect} from "react-redux";
import Dashboard from "../../components/dashboard";
import {Redirect, Route} from "react-router-dom";
import Companies from "./content/companies/companies";
import Settings from "./content/settings/settings";

function Panel () {

    return(
        <Dashboard>
            <Navigation/>
            <div style={{flex:'1 1',background:'rgb(240,240,240)',padding:'30px'}}>
                <Route path = '/panel/company' component={Companies} />
                <Route path = '/panel/settings' component={Settings} />

                <Route render={() => <Redirect to="/panel/company" />} />
            </div>
        </Dashboard>
    )
};

Panel.propTypes = {
};

function mapStateToProps(state) {
    return {
        isLoggingOut: state.auth.isLoggingOut,
        logoutError: state.auth.logoutError,
        user:state.auth.user
    };
}
export default connect(mapStateToProps)(Panel);