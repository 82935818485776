import { useState } from "react";
import axios from "axios";
import { getCookie } from "../../../../../theme/cookies";
import { connect } from "react-redux";
import Spinner from "../../../../../components/spinner";
import Button from "../../../../../components/button";
import Input from "../../../../../components/input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { Message } from "../../../../../theme/styledComponents";
import { API_URL } from "../../../../../config";

function EditCompany (props) {
    const {company, refresh, setShow} = props;
    const [loading,setLoading] = useState();
    const [error,setError] = useState(null);
    const [state,setState] = useState({username: company?.Username, name: company?.Name});

    const onSubmit = () => {
        axios.patch(`${API_URL}/admin/companies/${company.Id}`, state,
        {
            headers: {
                'Authorization': getCookie('token')
            }
        })
        .then(() => {
            refresh();
            setLoading(false);
            setShow({company: {}, show: false});
        })
        .catch((err) => {
            console.log(err);
            setError(err);
            setLoading(false);
        });
    };

    const onChange = (e) => {
        setState({
            ...state,
            [e.target.name]:e.target.value
        })
    }

    return(
        <>
            {
                error !== null &&
                <Message>
                    <FontAwesomeIcon icon={faExclamationTriangle}/>
                    <div>
                        Nie udało się zaktualizować danych firmy
                    </div>
                </Message>
            }

            <Input label="Nazwa użytkownika" name="username" value={state.username} onChange={onChange}/>
            <Input label="Nazwa" name="name" value={state.name} onChange={onChange}/>
            <Button big style={{marginTop:'10px'}} onClick={onSubmit}>
                {
                    loading ?
                        <Spinner borderWidth={3} white width={10} height={10}/> :
                        'Zapisz'
                }
            </Button>
        </>
    );
}

function mapStateToProps(state) {
    return {
        user:state.auth.user
    };
}
export default connect(mapStateToProps)(EditCompany);