import React, {useState} from 'react';
import Navigation from "./navigation/navigation";
import {connect} from "react-redux";
import Dashboard from "../../components/dashboard";
import {Redirect, Route} from "react-router-dom";
import Drivers from "./content/drivers/drivers";
import Configuration from "./content/configuration/configuration";
import Modal from "../../components/modal";
import PlateNumbers from "./content/platesNumbers/plateNumbers";
import Vehicles from "./content/vehicles/vehicles";

function Panel (props) {
    const [showConfiguration,setShowCongiuration] = useState(false);
    const [showPlateNumbers,setShowPlateNumbers] = useState(false);

    return(
        <Dashboard>
            <Navigation setShowCongiuration={setShowCongiuration} setShowPlateNumbers={setShowPlateNumbers}/>
            <div style={{flex:'1 1',background:'rgb(240,240,240)',padding:'30px', overflowY:'scroll'}}>
                <Route path = '/panel/drivers' component={Drivers} />
                <Route path = '/panel/vehicles' component={Vehicles} />
                <Route path = '/panel/drivers/configuration' component={Configuration} />

                <Route render={() => <Redirect to="/panel/drivers" />} />

                <Modal show={showConfiguration} setShow={() => setShowCongiuration(false)} title="Konfiguracja" maxWidth="600" rightModal>
                    <Configuration/>
                </Modal>

                {/*<Modal show={showPlateNumbers} setShow={() => setShowPlateNumbers(false)} title="Numery rejestracyjne" maxWidth="400" rightModal>*/}
                {/*    <PlateNumbers/>*/}
                {/*</Modal>*/}
            </div>
        </Dashboard>
    )
};

Panel.propTypes = {
};

function mapStateToProps(state) {
    return {
        isLoggingOut: state.auth.isLoggingOut,
        logoutError: state.auth.logoutError,
        user:state.auth.user
    };
}
export default connect(mapStateToProps)(Panel);
