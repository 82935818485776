export const theme = {
  primaryColor:'#083049',
  secondColor:'rgb(39,39,39)',
  thirdColor:'rgb(100,100,100)',
  fourthColor:'rgb(235,235,235)',
  primaryBackground:'rgba(13, 119, 187,0.1)',

  green:'rgb(85,163,66)',
  greenBackground:'rgba(224,239,222,0.7)',
  blue:'rgb(77,177,249)',
  blueBackground:'rgba(214,237,251,0.6)',
  yellow:'rgb(245,180,64)',
  yellowBackground:'rgb(253,242,217)',
  red:'#cc0000',
  redBackground:'rgb(204, 0, 0, 0.1)',

  font:{
    XXS:'12px',
    XS:'14px',
    S:'16px',
    M:'18px',
    L:'20px',
    XL:'22px',
    XLL:'26px',
    XXL:'30px',

    Bold:'700',
    Regular:'600',
    Light:'500'
  },
};