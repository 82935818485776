import React from 'react';
import styled from 'styled-components';
import {FormControlLabel, withStyles} from "@material-ui/core";
import {theme} from "../theme/theme";
import {Checkbox as MUCheckbox} from '@material-ui/core';

const PrimaryCheckbox = withStyles({
    root: {
        color: theme.primaryColor,
        '&$checked': {
            color: theme.primaryColor,
        },
    },
    checked: {},
})((props) => <MUCheckbox color="default" {...props} />);

const Checkbox = ({value, onChange, label}) => {

    return (
        <StyledFormControlLabel
            control={
                <PrimaryCheckbox
                    checked={value}
                    PrimaryCheckbox={value}
                    onChange={onChange}
                    name="checkedB"
                    color="primary"
                />
            }
            label={label}
        />
    );
}

export default Checkbox;

const StyledFormControlLabel = styled(FormControlLabel)`
  margin-bottom: 10px;
`;