import React from 'react';
import styled, {css} from 'styled-components'
import TextField from '@material-ui/core/TextField';

function Input ({onChange,value,name,label,password,autoComplete}) {
    return(
        <Wrapper noLabel={!label}>
            <TextField id="filled-basic" variant="filled" type={password ? 'password' : 'text'} label={label} name={name} value={value} onChange={onChange} autoComplete={autoComplete}/>
        </Wrapper>
    )
};

Input.propTypes = {
};

export default Input;

const Wrapper = styled.div`
  width: 100%;
  margin: ${({margin}) => margin};
  margin-bottom: 10px;
  >div{
    margin: 0px;   
    width: 100%; 
  }
  .MuiFilledInput-underline:before,.MuiFilledInput-underline:after{
    display: none;
  }
  .MuiFilledInput-input{
    padding-top: 24px !important;
    padding-bottom: 8px !important;
  }
  .MuiFilledInput-root{
    border-radius: 5px !important;
    overflow: hidden;
    background: rgba(0,0,0,0.07) !important;
  }
  .MuiInputLabel-filled{
    font-weight: 500;
    color: ${({theme}) => theme.thirdColor};
    transform: translate(12px, 17px) scale(1);
  }
  .MuiInputLabel-filled.MuiInputLabel-shrink{
    transform: translate(12px, 9px) scale(0.7);
    color: ${({theme}) => theme.primaryColor};
  }
  
  ${({noLabel}) => noLabel && css`
    .MuiFilledInput-input{
      padding-top: 12px !important;
      padding-bottom: 12px !important;
    }
  `}
`;
