import React, {useState} from 'react';
import {Message} from "../../../../../theme/styledComponents";
import Input from "../../../../../components/input";
import Button from "../../../../../components/button";
import axios from "axios";
import {connect} from "react-redux";
import {getCookie} from "../../../../../theme/cookies";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";
import Spinner from "../../../../../components/spinner";
import {P} from "../../../../../components/p";
import { API_URL } from '../../../../../config';

function DeleteVehicle (props) {
    const {vehicle,setShow,refresh,user} = props;

    const [loading,setLoading] = useState();
    const [error,setError] = useState();

    const onSubmit = () => {
        setLoading(true);
        axios.patch(`${API_URL}/companies/${user.CompanyId}/vehicles/${vehicle.VehicleId}/disable`,null,{
            headers:{
                'Authorization': getCookie('token')
            }
        })
            .then(res => {
                refresh();
                setLoading(false);
                setShow(false);
            })
            .catch(err => {
              console.log(err)
                setError(true);
                setLoading(false);
            })
    };

    return(
        <>
            {
                error &&
                <Message>
                    <FontAwesomeIcon icon={faExclamationTriangle}/> Nie udało się usunąć dostępu.
                </Message>

            }
            <P>Czy na pewno chcesz usunąć dostęp do aplikacji dla pojazdu <span>{vehicle.Name}</span>?</P>
            <P>(pojazd zostanie zaarchiwizowany)</P>
            <div>
              <Button big style={{marginTop:'30px'}} onClick={onSubmit}>
                {
                  loading ?
                    <Spinner borderWidth={3} white width={10} height={10}/> :
                    'Usuń dostęp'
                }
              </Button>
            </div>
        </>
    )
};

function mapStateToProps(state) {
    return {
        user:state.auth.user
    };
}
export default connect(mapStateToProps)(DeleteVehicle);
